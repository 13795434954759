import Script from 'next/script'
import { useEffect, useRef, useState } from 'react'
import { Image, Autocomplete as ReshapedAutocomplete, View } from 'reshaped'

//TODO: replace with actual type definitions
declare global {
  interface Window {
    google: any
  }
}

interface AddressInputScriptProps {
  onPlaceSelected: (place: any) => void
}

export const AddressInputScript: React.FC<AddressInputScriptProps> = ({
  onPlaceSelected,
}) => {
  const autocompleteServiceRef = useRef<any>(null)
  const placesServiceRef = useRef<any>(null)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [suggestions, setSuggestions] = useState<any[]>([])
  const [inputValue, setInputValue] = useState('')
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null)

  //TODO: move this to env variable
  const googleKey = 'AIzaSyDYbn5ZDWJ5lXDDPShp4qu5Kg5e9AN3nOY'

  useEffect(() => {
    if (scriptLoaded && !autocompleteServiceRef.current) {
      if (window.google) {
        autocompleteServiceRef.current =
          new window.google.maps.places.AutocompleteService()
        placesServiceRef.current = new window.google.maps.places.PlacesService(
          document.createElement('div'),
        )
      }
    }
  }, [scriptLoaded])

  const fetchSuggestions = (input: string) => {
    if (autocompleteServiceRef.current && input.length > 0) {
      autocompleteServiceRef.current.getPlacePredictions(
        {
          input,
          types: ['address'],
          componentRestrictions: { country: 'us' },
        },
        (predictions: any[], status: string) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const mappedPredictions = predictions.map((prediction) => ({
              description: prediction.description,
              place_id: prediction.place_id,
              main_text: prediction.structured_formatting.main_text,
            }))
            setSuggestions(mappedPredictions)
          } else {
            setSuggestions([])
          }
        },
      )
    } else {
      setSuggestions([])
    }
  }

  const handleInputChange = (args: { value: string }) => {
    const input = args.value
    setInputValue(input)
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current)
    }
    debounceTimerRef.current = setTimeout(() => {
      fetchSuggestions(input)
    }, 300)
  }

  const handleItemSelect = (args: { value: string }) => {
    const selectedSuggestion = suggestions.find(
      (suggestion) => suggestion.description === args.value,
    )
    if (selectedSuggestion) {
      setInputValue(selectedSuggestion.main_text)
      setSuggestions([])

      if (placesServiceRef.current) {
        placesServiceRef.current.getDetails(
          { placeId: selectedSuggestion.place_id },
          (place: any, status: string) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              onPlaceSelected(place)
            }
          },
        )
      }
    }
  }

  return (
    <View>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`}
        strategy="afterInteractive"
        onLoad={() => {
          setScriptLoaded(true)
        }}
      />
      <ReshapedAutocomplete
        name="lead.personalInformation.address1"
        placeholder="e.g. 123 Main St"
        size="large"
        value={inputValue}
        onChange={handleInputChange}
        onItemSelect={handleItemSelect}
        inputAttributes={{
          autoComplete: 'off',
        }}
      >
        {suggestions.length > 0 && (
          <>
            {suggestions.map((suggestion) => (
              <ReshapedAutocomplete.Item
                key={suggestion.place_id}
                value={suggestion.description}
              >
                {suggestion.description}
              </ReshapedAutocomplete.Item>
            ))}
            <View align="end">
              <Image
                src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png"
                alt="Google Maps Logo"
                height={4}
              />
            </View>
          </>
        )}
      </ReshapedAutocomplete>
    </View>
  )
}
