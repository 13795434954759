'use client'
import type { RendererSteps } from '@capabilities/search-steps'
import { MarkdownText } from '@capabilities/ui-elements'
import { useMask } from '@react-input/mask'
import { Button, Checkbox, FormControl, Text, TextField, View } from 'reshaped'
import { createStepRenderer } from '../createStepRenderer'

export const SSNInputStepRenderer = createStepRenderer<RendererSteps['SSNInput']>(
  ({ headline, StepLayout, errors, loading, fields, status, subtext }) => {
    const inputRef = useMask({ mask: 'XXX-XX-XXXX', replacement: { X: /\d/ } })
    const { label, placeholder, name } = fields[0]
    const { label: smsLabel, name: smsName, description: smsDescription } = fields[1]
    const { label: buttonLabel, name: buttonName } = fields[2]
    const { value: tcpaLanguage, name: tcpa } = fields[3]
    const { value: frcaLanguage, name: fcra } = fields[4]

    return (
      <View gap={6}>
        <StepLayout.Title title={headline} />
        <StepLayout.Subtext subtext={subtext} />
        <FormControl size="large" hasError={errors?.some((error) => error.name === name)}>
          <FormControl.Label>{label}</FormControl.Label>
          <TextField
            size="large"
            name={name}
            placeholder={placeholder}
            inputAttributes={{
              inputMode: 'numeric',
              ref: inputRef,
            }}
          />
          <FormControl.Error>
            {errors?.find((error) => error.name === name)?.message}
          </FormControl.Error>
        </FormControl>
        <View maxWidth={50}>
          <Button
            type="submit"
            fullWidth
            size="large"
            variant="solid"
            color="positive"
            attributes={{ name: buttonName }}
            // continue loading while client is routing to the next screen
            loading={loading || status === 'success'}
          >
            {buttonLabel}
          </Button>
        </View>
        <FormControl size="large">
          <View direction="row" gap={2} wrap>
            <Checkbox name={smsName} attributes={{ style: { alignItems: 'start' } }}>
              <Text color="neutral-faded" variant="body-3" weight="bold">
                {smsLabel}
              </Text>
              <br />
              <MarkdownText
                color="neutral-faded"
                variant="body-3"
                content={smsDescription || ''}
              />
            </Checkbox>
          </View>
        </FormControl>
        <input name={tcpa} type="hidden" value={tcpaLanguage} />
        <input name={fcra} type="hidden" value={frcaLanguage} />
        <View>
          <Text color="neutral-faded" variant="body-3">
            <MarkdownText content={frcaLanguage} />
          </Text>
        </View>
      </View>
    )
  },
)
