'use client'

import type { ValidationResult } from '@capabilities/search-steps'
import {
  buildUrlWithSearchParams,
  useNavigationWithSearchParams,
} from '@utilities/unsafe'
import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

/**
 * Custom hook for redirecting on validation of a given search step.
 *
 * @param result the current step validation result
 */
export const useRedirectOnSuccess = (
  result: ValidationResult | undefined,
  cnfBasePathname: string,
) => {
  const searchParams = useSearchParams()

  const { navigateWithParams } = useNavigationWithSearchParams()
  const completeURL = buildUrlWithSearchParams(
    `${cnfBasePathname}/complete`,
    searchParams,
  )

  return useEffect(() => {
    if (result?.status === 'success') {
      if (result.nextStep) {
        navigateWithParams(`${cnfBasePathname}/${result.nextStep}`)
      } else {
        /**
         * Redirect to search/complete if no next step. Router push is not used
         * here to avoid synchronous navigation. This is likely due to routing
         * service issues.
         */
        window.location.href = completeURL
      }
    }
  }, [result, cnfBasePathname, navigateWithParams, completeURL])
}
